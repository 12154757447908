/// <reference path="../../types/hookrouter.d.ts" />

import { RoutesDefinition, usePath, useRoutes, getQueryParams } from 'hookrouter';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import '../scss/main.scss';
import { AppContext, Content, Location, Partner, Product, Purchase, User } from './AppContext';
import { ScreenWrapper } from './components/ScreenWrapper';
import { rs } from './i18n/rs';
import { DynamicScreen } from './screens/DynamicScreen';
import { ErrorScreen } from './screens/Error';
import Home from "./screens/Home";

if (/^\/r\/game\/?/.test(location.pathname)) {
    location.href = 'https://game.solagro.app';
}

const routes: RoutesDefinition = {
    '/': () => <Home/>,
    '/partners': () => <DynamicScreen screen={() => import('./screens/Partners')} />,
    '/gifts': () => <DynamicScreen screen={() => import('./screens/Gifts')} />,
    '/gifts/:id': (params) => (
        <DynamicScreen params={params} screen={() => import('./screens/GiftDetails')} />
    ),
    '/digital-gifts/:id': (params) => (
        <DynamicScreen params={params} screen={() => import('./screens/DigitalGiftDetails')} />
    ),
    '/profile': () => <DynamicScreen screen={() => import('./screens/Profile')} />,
    '/signin': () => <DynamicScreen screen={() => import('./screens/SignIn')} />,
    '/signup': () => <DynamicScreen screen={() => import('./screens/SignUp')} />,
    '/verify/:token': (params) => <DynamicScreen
        params={{ ...getQueryParams(), ...params }}
        screen={() => import('./screens/AccountVerified')} />,
    '/verification': () => <DynamicScreen screen={() => import('./screens/AccountVerification')} />,
    '/first-time': () => <DynamicScreen screen={() => import('./screens/TutorialScreen')} />,
    '/policy-updated': () => <DynamicScreen screen={() => import('./screens/PrivacyPolicyUpdated')} />,
    '/privacy-policy': () => <DynamicScreen screen={() => import('./screens/PrivacyPolicyDocument')} />,
    '/terms-and-conditions': () => <DynamicScreen screen={() => import('./screens/TermsAndConditions')} />,
    '/contest-terms': () => <DynamicScreen screen={() => import('./screens/ContestTerms')} />,
    '/forgot-password': () => <DynamicScreen screen={() => import('./screens/ForgotPassword')} />,
    '/reset-password/:token': (params) => (
        <DynamicScreen
            params={{ ...getQueryParams(), ...params }}
            screen={() => import('./screens/ResetPassword')}
        />
    ),
    '/game': () => <DynamicScreen screen={() => import('./screens/Game')} />,
    '/points': () => <DynamicScreen screen={() => import('./screens/Points')} />,
    '/contact': () => <DynamicScreen screen={() => import('./screens/Contact')} />,
    '/rankings': () => <DynamicScreen screen={() => import('./screens/Rankings')} />,
    '/contest-terms-updated': () => <DynamicScreen screen={() => import('./screens/ContestConditionsUpdated')} />,
    '/help': () => <DynamicScreen screen={() => import('./screens/Help')} />,
    '/redeem': () => <DynamicScreen screen={() => import('./screens/Redeem')} />,
    '/locations': () => <DynamicScreen screen={() => import('./screens/Locations')} />,
    '/locations/map': () => <DynamicScreen screen={() => import('./screens/LocationsMap')} />,
    '/purchased': () => <DynamicScreen screen={() => import('./screens/Purchased')} />,
    '/r/game': () => {
        location.href = 'https://game.solagro.app';
        return <div />;
    },
};

const App = () => {
    const page = useRoutes(routes);
    const path = usePath(false);

    const [user, setUser] = useState<User>({});
    const [locations, setLocations] = useState<Location[]>([]);
    const [gifts, setGifts] = useState<Product[]>([]);
    const [purchased, setPurchased] = useState<Purchase[]>([]);
    const [partners, setPartners] = useState<Partner[]>([]);
    const [content, setContent] = useState<Content>(rs);
    const [activePartner, setActivePartner] = useState('x6bc1xzqa9ms7d5glc92ghs');

    if (page === null) {
        return <ErrorScreen error="Page not found." />;
    }

    if (
        path === '/' ||
        path === '/signin' ||
        path === '/signup' ||
        path === '/partner-selection' ||
        path === '/forgot-password' ||
        path === '/verification' ||
        path === '/policy-updated' ||
        path === '/terms-and-conditions' ||
        path === '/privacy-policy' ||
        path.startsWith('/reset-password/') ||
        path.startsWith('/verify/')
    ) {
        return (
            <AppContext.Provider
                value={{
                    user,
                    setUser,
                    locations,
                    setLocations,
                    partners,
                    setPartners,
                    purchased,
                    setPurchased,
                    gifts,
                    setGifts,
                    content,
                    setContent,
                    activePartner,
                    setActivePartner,
                }}
            >
                {page}
            </AppContext.Provider>
        );
    }

    return (
        <AppContext.Provider
            value={{
                user,
                setUser,
                locations,
                setLocations,
                partners,
                setPartners,
                purchased,
                setPurchased,
                gifts,
                setGifts,
                content,
                setContent,
                activePartner,
                setActivePartner,
            }}
        >
            <ScreenWrapper>{page}</ScreenWrapper>
        </AppContext.Provider>
    );
};

ReactDOM.render(<App />, document.getElementById('root'));
