import { AppEnv, getEnv, schema, TYPE } from '@solagro/env';
import { join } from 'path';

export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_PROD = !IS_DEV;

export interface AppWebServerEnv extends AppEnv {
    SOLAGRO_APP_WEBSERVER_HOST: string;
    SOLAGRO_APP_WEBSERVER_PORT: number;
    SOLAGRO_APP_WEBSERVER_ROOT: string;
    SOLAGRO_COH_WEBSERVER_ROOT: string;
    SOLAGRO_COH_BASIC_AUTH_USERS: string;
    SOLAGRO_API_SERVER_URL: string;
    GM_PARTNER_PUBLIC_ID: string;
}

export const env = getEnv(
    schema<AppWebServerEnv>({
        // Host on which to bind HTTP server, defaults to 0.0.0.0
        SOLAGRO_APP_WEBSERVER_HOST: TYPE.string()
            .optional()
            .default('0.0.0.0'),
        // Port on which to bind HTTP server, defaults to 10282
        SOLAGRO_APP_WEBSERVER_PORT: TYPE.number()
            .optional()
            .default(10282),
        // Location of webroot folder, defaults to ../public
        SOLAGRO_APP_WEBSERVER_ROOT: TYPE.string()
            .optional()
            .default(join(__dirname, '..', 'public')),
        // Location of CoH folder, defaults to ../coh
        SOLAGRO_COH_WEBSERVER_ROOT: TYPE.string()
            .optional()
            .default(join(__dirname, '..', 'coh')),
        // List of user/password pairs for /__coh basic auth in format "user1:pass1;user2:pass2"
        SOLAGRO_COH_BASIC_AUTH_USERS: TYPE.string()
            .optional()
            .default(''),
        // URL of the Solagro API server
        SOLAGRO_API_SERVER_URL: TYPE.string()
            .optional()
            .default('https://api.solagroteam.com/v1'),
        // GM partner public ID
        GM_PARTNER_PUBLIC_ID: TYPE.string()
            .optional()
            .default('3xw0ib508r9ihcfz5eg4xqc'),
    }),
);
