export const rs = {
    home: {
        signin: 'VEĆ IMAS NALOG? PRIJAVI SE',
        register: 'REGISTRUJ SE',
        collect_cans: 'Sakupljaj ambalažu\nsa nalepnicom',
        recycle_cans: 'Recikliraj ambalažu sa nalepnicom\nu pametnim reciklažnim uređajima',
        recycle_cans_sub: 'Pametne prese se nalaze u Idea prodajnim\nobjektima pronađi lokacije',
        machine_code: 'NA UREĐAJU ĆE SE PRIKAZATI\nKÔD KOJI BI TREBALO\nDA UNESEŠ U APLIKACIJU',
        enjoy: 'Ostvari popust u prodavnicama',
        enter_code: 'UBACI KÔD\nSA PAMETNOG UREĐAJA',
        collect_points: 'ISKORISTI PRIKUPLJENE POENE\nI ODABERI NAGRADE',
        press_locations: 'LOKACIJE PAMETNIH UREĐAJA\nU ZRENJANINU',
        signin_with_facebook: 'Prijavi se sa Facebook-om'
    },
    locations: {
        nearest: 'Najbliža lokacija:',
        location_title: 'IDEA Organic',
        location_cta: 'STARTUJ NAVIGACIJU',
    },
    points: {
        total_points: 'Osvojenih poena',
        add_points: 'DODAJ POENE',
    },
    profile: {
        beginner: 'Početnik',
        professional: 'Profesionalac',
        maestro: 'Majstor',
        grand_maestro: 'Velemajstor',
        genius: 'Genije',
        welcome: 'DOBRO DOŠLI',
        supergenius: 'SUPERGENIJE',
        username: 'Korisničko ime',
        statistics: 'Statistika',
        recycled_bottles: 'JEDINICA RECIKLIRANE AMBALAŽE',
        energy_saved: 'UŠTEDA STRUJE U KW',
        co2_saved: `MANJE CO2 U KG`,
    },
    purchased: {
        no_reservations: 'Niste rezervisali nijedan poklon',
    },
    redeem: {
        input_code: 'Unesite kod sa prese i potvrdite unos',
        confirm: 'Potvrdi',
        err_nocode: 'Morate uneti kod.',
        err_invalid: 'Kod nije validan!',
    },
    signin: {
        err_no_email: 'Morate uneti e-mail adresu',
        err_invalid_email: 'Morate uneti ispravnu e-mail adresu',
        err_no_pass: 'Morate uneti lozinku',
        err_invalid_pass: 'E-mail ili lozinka nisu ispravni',
        login: 'Uloguj se',
        register: 'Nemate nalog? Registrujte se.',
        forgot_password: 'Zaboravili ste lozinku?',
        email: 'E-mail adresa',
        password: 'Lozinka',
        social_login: 'Ulogujte se na drugi način',
    },
    forgot_password: {
        err_no_email: 'Morate uneti e-mail adresu',
        err_invalid_email: 'Morate uneti ispravnu e-mail adresu',
        err_unknown: 'Došlo je do greške. Molimo Vas pokušajte ponovo.',
        msg_success:
            'Ukoliko postoji nalog sa unetom e-mail adresom dobićete uputstva za resetovanje lozinke putem e-mail poruke.',
        email: 'E-mail adresa',
        reset_password: 'Resetuj lozinku',
        register: 'Nemate nalog? Registrujte se.',
        login: 'Uloguj se',
        instructions: 'Unesite e-mail adresu sa kojom ste se registrovali i mi ćemo Vam poslati poruku sa instrukcijama za promenu lozinke.',
        back: 'Nazad',
    },
    reset_password: {
        err_no_pass: 'Morate uneti lozinku',
        err_short_pass: 'Lozinka mora biti duža od 5 karaktera',
        err_no_confirm: 'Morate ponoviti lozinku',
        err_no_match: 'Lozinke se ne podudaraju',
        err_unknown: 'Došlo je do greške. Molimo Vas pokušajte ponovo.',
        msg_success: 'Uspešno ste promenili lozinku. Sada se možete prijaviti koristeći novu lozinku.',
        msg_invalid_token: 'Ovaj link više ne važi, molimo Vas zatražite novi link za resetovanje lozinke.',
        change_password: 'Izmenite lozinku',
        login: 'Prijavi se',
        instructions: 'Unesite novu lozinku',
        back: 'Nazad',
        pass: 'Lozinka',
        confirm_pass: 'Ponovite lozinku',
    },
    privacy_policy: {
        privacy_policy_title: 'Politika privatnosti',
        terms_and_conditions: `Uslovi korišćenja`,
        privacy_policy_updated: `Izmenili smo našu Politiku privatnosti i želimo da sa Vama podelimo detalje.`,
        agree: `Slažem se`,
        review: 'Politika privatnosti',
        contest_conditions_updated: `Klikom na dugme "Slažem se" prihvatate pravila nagradnog konkursa i stičete pravo za učestvovanje u istom.`,
        contest_conditions_link: 'Uslovi takmičenja',
    },
    account_verification: {
        please_verify: 'Molimo verifikujte Vaš nalog',
        account_verified: 'Nalog verifikovan!',
        account_verified_expl: 'Sada se možete prijaviti na Vaš nalog.',
        check_email: 'Molimo proverite email sanduče za verifikaciju naloga.',
        back: 'Nazad',
    },
    signup: {
        err_no_username: 'Morate uneti korisničko ime',
        err_short_username: 'Korisničko ime mora biti duže od 4 karaktera',
        err_invalid_username: 'Korisničko ime sadrži nedozvoljene karaktere',
        err_no_pass: 'Morate uneti lozinku',
        err_short_pass: 'Lozinka mora biti duža od 5 karaktera',
        err_no_confirm: 'Morate ponoviti lozinku',
        err_no_match: 'Lozinke se ne podudaraju',
        err_no_email: 'Morate uneti e-mail adresu',
        err_invalid_email: 'Morate uneti ispravnu e-mail adresu',
        err_try_again: 'Greška, pokušajte ponovo',
        err_policy: 'Greška, politika privatnosti nije prihvaćena',
        username: 'Korisničko ime',
        choose_gender: 'Odaberite pol',
        gender_array: ['muški', 'ženski', 'nepoznato', 'drugo'],
        age: "Godine starosti",
        email: 'E-mail',
        pass: 'Lozinka',
        confirm_pass: 'Ponovite lozinku',
        signup: 'Registruj se',
        has_account: 'Već imate nalog? Prijavite se.',
    },
    gift_details: {
        sponsor: 'Nagradu obezbeđuju:',
        success: 'Uspesno ste kupili ovaj proizvod!',
        failed: 'Kupovina nije uspela. Pokusajte ponovo.',
        not_enough_points: 'Nemate dovoljno poena!',
        available: 'Trenutno dostupno:',
        reserve: 'REZERVIŠI NAGRADU',
        unavailable: 'Trazeni proizvod trenutno nije dostupan!',
        are_you_sure: 'Da li ste sigurni da želite da potrošite %s poena za ovu nagradu?',
        yes: 'Da',
        no: 'Ne',
    },
    rankings: {
        monthly_list_title: 'RANG LISTA',
    },
};
