import {navigate} from "hookrouter";
import React, {useContext} from "react";
import one from "../../assets/images/1.png";
import two from "../../assets/images/2.png";
import three from "../../assets/images/3.png";
import bg from "../../assets/images/bcg.png";
import qr_code from "../../assets/images/qr_home.png";
import footerNew from "../../assets/images/footer.png";
import headerBeli from "../../assets/images/header-beli.png";
import idea from "../../assets/images/idea.png";
import roda from "../../assets/images/roda.png";
import solagroIcon from "../../assets/images/slgr_logo.png";
import ambalaze from "../../assets/images/flase.png";
import {AppContext} from "../AppContext";
import {Button} from "../components/Button";
import {useValidateAuth} from "../hooks/api";
import {en} from "../i18n/en";
import {rs} from "../i18n/rs";
import {LoadingScreen} from "./LoadingScreen";

const Home = () => {
    const isLoading = useValidateAuth({onSuccess: '/gifts', initialLoading: true});
    const context = useContext(AppContext);

    if (isLoading) {
        return <LoadingScreen/>;
    }

    return <div className="u-container-column" style={{background: 'white'}}>
        <img src={bg} className="c-home-center__background"/>
        <div className="c-home-wrapper">
            <div className="c-home-header">
                <div className="c-home-language">
                    <a onClick={() => context.setContent(rs)}>[SRP]</a>
                    <a style={{marginLeft: '8px'}} onClick={() => context.setContent(en)}>[ENG]</a>
                </div>
                <img src={headerBeli} style={{width: '70%'}}/>
            </div>
            <div className="c-home-center">
                <span style={{color: '#76AE45', fontSize: '8px', margin: '0 auto', marginTop: '20px'}}>powered by</span>
                <img src={solagroIcon} style={{height: '50px', width: '70px', alignSelf: 'center'}}/>
                <div className="c-home-center__main">

                    <div className="c-home-center__main__titles">
                        <div className="c-home-center__main__titles__element">
                            <img src={one} className="c-home-center__main__titles__element__image"/>
                            <div
                                className="c-home-center__main__titles__element__text_wrap__text">{context.content.home.collect_cans}</div>
                            <img style={{maxHeight: '70px', marginLeft: '20px'}} src={qr_code}/>
                        </div>
                        <div className="c-home-center__main__titles__element">
                            <img src={two} className="c-home-center__main__titles__element__image"/>
                            <div className="c-home-center__main__titles__element__text_wrap">
                                <div
                                    className="c-home-center__main__titles__element__text_wrap__text">{context.content.home.recycle_cans}</div>
                            </div>

                        </div>
                        <div className="c-home-center__main__titles__element">
                            <img src={three} className="c-home-center__main__titles__element__image"/>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div
                                    className="c-home-center__main__titles__element__text_wrap__text">{context.content.home.enjoy}</div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginLeft: '25px'
                                }}>
                                    <img src={idea} style={{maxWidth: '15%'}}/>
                                    <img src={roda} style={{maxWidth: '20%', marginLeft: '5px'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Button
                    className="c-btn c-btn--text u-vertical-margin-small"
                    style={{
                        marginTop: '20px',
                        width: '50%',
                        marginLeft: '0px',
                        minWidth: 240,
                        fontWeight: '600',
                        borderRadius: '10',
                        color: 'white',
                        backgroundColor: 'green',
                        fontSize: '12px'
                    }}
                    type="button"
                    onClick={() => navigate('/signup')}
                    color="white"
                >
                    {context.content.home.register}
                </Button>
                <Button
                    className="c-btn c-btn--text u-vertical-margin-small"
                    style={{
                        width: '50%',
                        minWidth: 240,
                        marginLeft: '0px',
                        height: '40px',
                        fontWeight: '600',
                        borderRadius: '10',
                        color: 'white',
                        backgroundColor: '#76AE45',
                        fontSize: '12px'
                    }}
                    type="button"
                    onClick={() => navigate('/signin')}
                    color="white"
                >
                    {context.content.home.signin}
                </Button>
            </div>
            <img src={ambalaze} style={{width: '50%', zIndex: '1'}}/>
            <img style={{maxWidth: '70%'}} src={footerNew}/>
        </div>
    </div>;
};

export default Home;
